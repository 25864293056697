import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: '',
    scrollBehavior: () => ({
        y: 0
    }),
    routes: [{
        path: '/',
        component: () => import('./Home.vue')
    }
]

})

export default router